@import "appVaribles";
@import "appCommonStyle";
@import "appIcons";

/*----------main header -----------------*/
// .drop{
//   height: 120px!important;
//   line-height: 2;
// }
.navbar-light {
  .navbar-brand {
    max-width: 350px;
  }
}

.main-header {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 2;
  box-shadow: 0px 2px 6px 1px rgba(0, 0, 0, 0.32);

  .top-nav {
    // height: $top-nav-height;
    position: relative;
    z-index: 2;
    background-color: #fff;
    border-bottom: 2px solid #054f7c;

    .navbar {
      padding: 0px;
      background-color: transparent;
      justify-content: space-between;
    }

    .navbar-brand {
      padding: 0px 0;
      position: relative;
      z-index: 1;
    }

    .app-title {
      padding: 26px 0;
      font-family: $font-boldItalic;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      margin: auto;
      text-align: center;

      .navbar-brand {
        padding: 8px 0;
        position: relative;
        z-index: 1;
      }

      .tough-title {
        color: $app-primary-color;
        font-size: 22px;
        line-height: 27px;
        margin-bottom: 0px;
        letter-spacing: 0.9px;
      }

      .made-title {
        color: $app-secondary-color;
        font-size: 15px;
        line-height: 18px;
        margin-bottom: 0px;
      }
    }
  }

  .right-box {
    position: relative;
    z-index: 1;
    @extend %display-flex;
  }

  .user-login {
    position: absolute;
    right: 70px !important;
    top: -75px;
    z-index: 3;
    @extend %display-flex;

    .nav-link {
      padding: 0 16px;
      @extend %display-flex;
      align-items: center;
      padding-bottom: 10px !important;

      &:hover {
        i {
          color: $app-secondary-color;
        }
      }
    }

    .user-name {
      font-size: 14px;
      color: #3f3f41;
      padding-right: 40px;
      display: inline-block;
      vertical-align: middle;
      letter-spacing: 0.3px;
      font-weight: bold;
      text-transform: capitalize;
    }

    i {
      display: inline-block;
      vertical-align: middle;
      font-size: 30px;
      color: #3f3f41;
    }
  }

  .add-cart {
    padding: 0 16px;
    font-size: 30px;
    color: #3f3f41;
    display: inline-block;
    vertical-align: middle;
    line-height: normal;
    position: relative;

    span {
      // font-family: $font-bold;
      font-weight: bolder;
      font-size: 16px;
      position: absolute;
      top: 9px;
      left: 0px;
      right: 0;
      margin: auto;
      text-align: center;
    }

    &:hover {
      i,
      span {
        color: $app-secondary-color;
      }
    }
  }

  .right-box-navbar {
    .cart-icon-login-navbar {
      display: none;
    }
  }

  .custom-dropdown {
    background-color: black;
  }

  .menu-toggle {
    display: none;
    padding: 3px 0px;
    border: none;
    outline: none;
    @extend %common-transition;

    svg {
      stroke: $app-secondary-color;
      width: 48px;
      height: 71px;
      margin-top: -18px !important;
      right: 6px !important;
    }

    &:hover {
      svg {
        stroke: $app-secondary-color;
      }
    }
  }

  .navv {
    display: block;
    @extend %common-transition;

    .menu-close-button {
      display: none;
      padding: 3px 16px;
      position: absolute;
      right: 14px;
      top: 14px;
      font-size: 30px;

      i {
        color: $app-primary-color;
      }
    }
  }

  .nav-Image-width {
    max-width: 335px;
    cursor: pointer;
  }

  // .pop-over{
  //   .action-button {
  //     display: inline-block;

  .pop {
    .action-button {
      display: inline-block;

      height: 30px;
      width: 30px;
      background: #d3d3d3;
      color: #000;
      font-size: 16px;
      line-height: 30px;
      border-radius: 3px;
      @extend %common-transition;
      margin-right: 10px;
      display: none;
      padding: 15px 20px;

      position: fixed !important;

      // position: absolute;

      // right: 14px;
      // top: 14px;
      // font-size: 30px;
      // i {
      //   color: $app-primary-color;
      // }
      &:hover {
        background-color: #000;
        color: #fff;
      }

      &:last-child {
        margin-right: 0px;
      }
    }
  }

  .app-links-nav {
    display: block;
    background-color: $app-primary-color;
    @extend %common-transition;

    .menu-close-button {
      display: none;
      padding: 3px 16px;
      position: absolute;
      right: 14px;
      top: 14px;
      font-size: 30px;

      i {
        color: $app-primary-color;
      }
    }

    .okapi {
      // width: 200px;
      width: 60px;
    }

    .nav-item {
      position: relative;
      margin: 0px 0.5rem;
    }

    .nav-link {
      position: relative;
      line-height: 50px;
      padding: 0 10px;
      font-size: 18px;
      color: #fff;
      border-bottom: 2px solid transparent;
      @include css3-prefix(transition, color 100ms ease-in-out);

      &:hover:not(.active),
      &:focus:not(.active) {
        color: #fff;
      }

      &:hover,
      &:focus {
        border-bottom-color: #054f7b;
      }

      &.active {
        &:before {
          opacity: 1;
        }
      }
    }

    .header-search {
      padding: 8px 0 8px 12px !important;
    }

    .header-search-box {
      position: relative;
      margin-bottom: 0px;
      width: 300px;

      input {
        height: 32px;
        border-radius: 6px;
        min-width: 300px;
        background-color: #acc1ce;
        font-size: 14px;
        color: #054f7b;
        padding-right: 30px;
        border: none;
        outline: none;
        box-shadow: none;
        border: 1px solid transparent;
        @include css3-prefix(transition, all 100ms ease-in-out);

        &:focus {
          border-color: $app-primary-color;

          & + i {
            color: #000;
          }
        }
      }

      ::-webkit-input-placeholder {
        color: $app-primary-color;
      }

      ::-moz-placeholder {
        color: $app-primary-color;
      }

      :-ms-input-placeholder {
        color: $app-primary-color;
      }

      :-moz-placeholder {
        color: $app-primary-color;
      }

      i {
        position: absolute;
        right: 8px;
        top: 0px;
        color: $app-primary-color;
        font-size: 14px;
        height: 14px;
        bottom: 0;
        margin: auto;
      }
    }
  }

  &.login-header {
    .top-nav .app-title {
      padding-top: 28px;
    }

    .app-links-nav {
      display: none;
    }
  }
}

.nav-container {
  position: relative;
}

/*----------main footer -----------------*/

.main-footer {
  background-color: #efeff0;
  position: relative;
  bottom: 0;
  left: 0;
  width: 100%;

  .logo {
    max-width: 280px;
    display: none;
    vertical-align: super;

    img {
      width: 100%;
    }
  }

  .left-box {
    font-size: 14px;
  }

  .cust-container {
    @extend %display-flex;
    justify-content: space-between;
    align-items: center;
  }

  .center-box {
    font-size: 12px;
    color: #000;
    // padding: 7px 0;
    // position: absolute;
    text-align: center;
    left: 0;
    right: 0;
    top: 0px;
    margin: auto;
    max-width: 350px;
    bottom: 0;

    span {
      background-color: #58585a;
      position: absolute;
      height: 42px;
      width: 42px;
      border-radius: 50%;
      font-size: 18px;
      padding: 7px;
      top: 0;
      bottom: 0;
      margin: auto;
      display: inline-block;
    }

    a.logo {
      display: none;
      vertical-align: middle;
      width: 53px;
      margin: 0 9px;
    }
  }

  .footer-link {
    line-height: 15px;
    margin-right: 22px;
    line-height: 50px;
    white-space: nowrap;

    .terms {
      font-size: 14px;
      line-height: 50px;
      color: #fff;
      text-decoration: underline;
      padding: 0 3px;
      top: -2px;
      position: relative;
      @extend %common-transition;

      &::before {
        content: "";
        position: absolute;
        height: 13px;
        width: 1px;
        right: -5px;
        top: 16px;
        background-color: #fff;
      }

      &:hover {
        color: $app-secondary-color;
        text-decoration: underline;
      }

      // &:hover {
      //   span {
      //     color: $app-secondary-color;
      //   }
      // }
      // &:last-child {
      //   margin-right: 0px;
      // }
    }

    i,
    span {
      @extend %common-transition;
    }

    i {
      font-size: 12px;
      color: #000;
      vertical-align: text-bottom;
    }

    span {
      color: #000;
      font-size: 12px;
      padding-left: 12px;
      line-height: normal;
    }

    &:hover {
      span {
        color: $app-secondary-color;
      }
    }

    &:last-child {
      margin-right: 0px;
    }
  }

  nav {
    ul {
      margin: 0px;
    }

    li {
      p {
        font-family: $font-light;
        font-size: 12px;
        color: #000;
        text-decoration: underline;
        padding: 0 3px;
        position: relative;
        margin-bottom: 0;
        cursor: pointer;
        @extend %common-transition;

        &::before {
          content: "";
          position: absolute;
          height: 13px;
          width: 1px;
          right: -5px;
          top: 2px;
          background-color: #000;
        }

        &:hover {
          color: $app-secondary-color;
          text-decoration: underline;
        }
      }

      &:last-child {
        p {
          &:before {
            display: none;
          }
        }
      }
    }
  }
}

.login-footer {
  .main-footer {
    height: 50px !important;

    .center-box {
      top: 0px !important;
      left: 0px !important;
      right: 0px !important;
      margin: auto;
      color: #000;
      font-size: 12px;
      line-height: 32px;

      span {
        display: none;
      }
    }
  }
}

a.text-black {
  color: #000;
}

/*----------main container --------------*/
.main-wrapper {
  position: relative;
}

main {
  padding-top: 220px;
  min-height: 100vh;
  padding-bottom: $footer-height;

  &.login-main-page {
    padding-bottom: 0 !important;
    padding-top: 100px;
  }
}

/*----------login section----------------*/
.login-section {
  @include calc(min-height, "100vh - #{$top-nav-height} - #{$footer-height}");
  @include BannerBackground("../images/login-bg", "jpg");
  @extend %display-flex;
  align-items: center;
  justify-content: center;
  padding: 10px;

  .common-button {
    background-color: transparent;
    color: #333a45;
    max-width: 288px;
    width: 100%;
    height: 61px;
    border: 1px solid #333a45;
    border-radius: 0;
    font-size: 22px;
  }

  .login-box {
    position: relative;
    width: 631px;
    height: 600px;
    margin-top: 80px;
    border-radius: 0px;
    border: none;
    // background-image: url('../images/login-box-bg.svg');
    background-repeat: no-repeat;
    background-position: 100.1% 100.5%;

    .section-header {
      span {
        margin: 0 57px;
        padding: 0;
        text-align: center;
      }
    }

    .cust-control {
      input {
        border-bottom: 1px solid #000;
        background-position: right calc(0.375em + 0.1875rem) center;

        &::placeholder {
          color: #000000;
        }
      }

      .form-control.is-invalid {
        border-color: #dc3545;
      }
    }

    .bottom-links {
      position: absolute;
      bottom: 26px;
      left: 0px;
      right: 0px;
      margin: auto;
      font-family: $font-light;
      font-size: 14px;
      color: #000;
      text-decoration: underline;
      text-align: center;

      a {
        display: inline-block;
        height: 25px;
        line-height: 25px;
        text-transform: capitalize;
        // font-family: "HelveticaNeue-Bold";
        font-weight: bolder;
        font-size: 14px;
        border-radius: 5px;
        padding: 0 15px;
        text-align: center;
        background-color: #1d5532;
        color: #fff;
        border: none;
        text-decoration: none;
        @extend %common-transition;

        &:hover {
          background: #000;
          color: #fff;
        }
      }
    }

    .login-form {
      max-width: 318px;
      padding: 0 15px;
      margin: 0 auto;
    }

    .btn-box {
      margin-top: 30px;
      margin-bottom: 30px;
    }

    .forget-link {
      font-family: $font-light;
      font-size: 14px;
      color: #a2a2a2;
      @extend %common-transition;

      &:hover {
        color: darken(#a2a2a2, 20%);
      }
    }
  }
}

/*--------content-page---------------------*/
.content-page {
  // padding-top: 65px;
  .section-row {
    @extend %display-flex;
    margin-left: -10px;
    margin-right: -10px;

    .section-col {
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  .section-header {
    margin-bottom: 20px;
  }
}

/*------------home-section---------------------*/
.view {
  color: #1d5532;
  font-style: italic;
}

.with-abs-button {
  position: relative;

  button {
    position: absolute;
    right: 50px;
    top: 17px;
  }
}

.invoice-header {
  margin-top: -50px;
}

.invoice-grid-box {
  .invoice-grid-scroll-container {
    overflow-x: auto;
  }

  .invoice-table {
    width: 100%;

    thead {
      margin-top: -10px;

      th {
        background-color: $app-primary-color;
        height: 40px;
        vertical-align: middle;
        // font-family: $font-bold;
        font-weight: bolder;
        font-weight: normal;
        font-size: 14px;
        color: #fff;
        border: none;
        padding: 5px 8px;

        &.action-col {
          text-align: center;
        }
      }
    }

    tbody {
      tr {
        height: 20px;

        &:hover {
          background-color: #f7f7f7;
        }

        td,
        th {
          border: none;
          font-family: $font-normal;
          font-size: 14px;
          color: #000;
          height: 30px;
          vertical-align: middle;
          padding: 12px 8px;

          &.action-col {
            padding-top: 0px;
            padding-bottom: 0px;
            text-align: center;
          }

          .react-toggle {
            vertical-align: middle;
          }
        }
      }
    }

    &.with-border {
      border-left: 1px solid #000;
      border-right: 1px solid #000;

      th,
      td {
        border-bottom: 1px solid #000;
      }
    }
  }
}

.home-section {
  .no-promotion {
    margin-top: -130px;
  }

  .section-row {
    flex-wrap: nowrap;

    .section-col {
      &.left-col {
        max-width: 500px;
        min-width: 500px;

        // margin-top: -130px;
      }

      &.right-col {
        flex: 1;
      }
    }
  }

  .material-section {
    padding: 54px 0;
    @include BannerBackground("../images/material-section-bg", "jpg");
    background-attachment: fixed;
    width: 100%;
    overflow: hidden;

    h2,
    h4 {
      position: relative;
      z-index: 1;
      font-family: $font-boldItalic;
      margin-bottom: 0px;
      text-transform: uppercase;
    }

    h2 {
      font-size: 40px;

      line-height: 50px;
    }

    h4 {
      font-size: 15px;
    }

    .material-links-row {
      @extend %display-flex;
      margin-bottom: 54px;

      .material-box {
        flex: 1;
        height: 450px;
        position: relative;
        outline: none;
        @extend %display-flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        h2 {
          color: #fff;
        }

        h4 {
          color: $app-secondary-color;
        }

        &:before {
          content: "";
          position: absolute;
          left: 0;
          right: 0;
          margin: auto;
          top: 0;
          height: 100%;
          @include calc(width, "100% - 54px");
          background-color: $app-primary-color;
          @include css3-prefix(transform, skewX(-16deg));
        }
      }
    }

    .subscribe-box {
      height: 241px;
      position: relative;
      @extend %display-flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 382px 0 66px;
      color: $app-secondary-color;

      &:before {
        content: "";
        position: absolute;
        top: 0px;
        height: 100%;
        width: 200%;
        background-color: $app-secondary-color;
        right: 160px;
        border-radius: 0px 90px 47px 0;
        @include css3-prefix(transform, skewX(-16deg));
      }

      .common-button {
        border: 1px solid #fff;
      }

      h2,
      h4 {
        color: $app-primary-color;
      }

      form {
        min-width: 290px;
        position: relative;
      }
    }
  }

  .newslist-wrapper {
    .news-item {
      cursor: pointer;
    }
  }
}

/*----------news list-------------------------*/
.newslist-wrapper {
  // padding-left: 64px;
  .news-item {
    margin-bottom: 35px;

    span {
      margin-bottom: 5px;
    }

    span,
    p {
      font-size: 14px;
      color: #000;
      display: block;
      line-height: 16px;
    }

    h4 {
      // font-family: $font-bold;
      font-weight: bolder;
      font-size: 20px;
      color: #000;
      line-height: 25px;
    }
  }
}

/*------------popup--------------------------*/
.confirm-popup {
  .popup-text {
    text-align: center;
    font-size: 18px;
    padding: 10px 0;
  }
}

/*--------news-addEdit---------------------*/
.news-addEdit {
  .image-uploads {
    .form-group {
      display: inline-block;
      vertical-align: top;
      min-width: 240px;

      img {
        max-width: 100%;
      }

      label {
        display: block;
      }

      input {
        display: block;
        margin-bottom: 10px;
      }

      .thumbnail {
        width: 102px;
        min-width: 102px;
        display: inline-block;
        padding: 4px;
        margin-bottom: 0px;
        line-height: 1.42857143;
        background-color: #fff;
        border: 1px solid #ddd;
        border-radius: 4px;

        &.image-large {
          width: auto;
        }
      }
    }
  }
}

/*---------------grids------------------*/
.news-grid {
  .Title-col {
    min-width: 95px;
  }

  .Content-col {
    min-width: 320px;
  }

  .Thumbnail-col {
    min-width: 200px;
  }

  .ImageUrl-col {
    min-width: 200px;
  }
}

/*--------------marketing section------------*/

.marketing-client-section {
  .marketing-table {
    min-width: 650px;

    .date-col {
      min-width: 100px;
    }
  }
}

.email-link {
  // margin-right: 20px;
  color: blue;
}

/*------------------training-------------*/
.traning-client-section {
  .training-grid {
    min-width: 650px;

    .date-col {
      min-width: 100px;
    }
  }
}

/*-------admin-dashboard---------*/
.section-header {
  background: unset;

  span {
    padding-left: 0;

    .text {
      margin-bottom: 100px;
      position: relative;
    }
  }

  .input-box {
    margin-top: -8px;
  }
}

/*------------newsCustomer-section----------*/
.newsCustomer-section {
  .newsbox-container {
    overflow: hidden;
  }

  .news-row {
    margin-right: -112.5px;
    margin-left: -112.5px;

    .news-col {
      padding: 0 112px;
    }
  }

  .news-card {
    border: none;
    margin-bottom: 85px;
    cursor: pointer;

    .card-body {
      padding: 0px;
    }

    .card-img-top {
      margin-bottom: 19px;
    }

    .card-title {
      color: #000;
      font-family: $font-normal;
      font-size: 16px;
      line-height: 20px;
      margin-bottom: 17px;
    }

    .card-subtitle {
      font-family: $font-boldItalic;
      font-size: 24px;
      color: $app-primary-color;
      line-height: 29px;
      text-transform: uppercase;
      margin-bottom: 45px;
    }

    .card-text {
      font-size: 16px;
      color: #000;
    }
  }
}

/*------------fullNews-page-------------------*/
.fullNews-page {
  .news-banner {
    height: 486px;
    background-color: #eee;
    @include BannerBackground("../images/news-banner", "png");
  }

  .news-container {
    max-width: 1246px;
    margin: 0 auto;
    padding: 0 15px;
    position: relative;
    padding-top: 44px;
  }

  .close-button {
    position: absolute;
    right: 15px;
    top: 42px;
  }

  .news-title {
    color: #000;
    font-family: $font-normal;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 17px;
  }

  .news-sub-title {
    font-family: $font-boldItalic;
    font-size: 24px;
    color: $app-primary-color;
    line-height: 29px;
    text-transform: uppercase;
    margin-bottom: 45px;
  }

  .news-text-container {
    p {
      font-size: 16px;
      color: #000;
      line-height: 33px;
    }
  }
}

/*------------catalogue----------------*/
.catalogue-section {
  .footer-button-box {
    padding-top: 24px;
  }

  .sub-menu {
    padding: 0px;

    li {
      margin-bottom: 6px;

      &:last-child {
        margin-bottom: 0px;
      }
    }
  }

  .accordion {
    .card-body {
      padding: 10px 0px;
    }
  }
}

// .add-icon{
//   position: relative;
// }
.cam-btn {
  color: #1d5532;
}

// .fix-width-card{
//   min-width: 340px;
// }
.catalogue-card {
  // border: 1px solid #000;
  position: relative;
  border-radius: 0px;

  margin-bottom: 20px;

  .app-chk .chk-style {
    background-color: rgba(255, 255, 255, 0.69);
  }

  .app-chk {
    position: absolute;
    top: 8px;
    left: 8px;
    z-index: 1;
  }

  .card-body {
    // border-top: 1px solid #000;
    padding: 0.2rem;
  }

  .product-image {
    height: 220px;
    background-repeat: no-repeat;
    background-position: center center;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
    // object-fit: cover;
    // width: 369px;
  }

  .card-row {
    &:last-child {
      margin-bottom: 0px;
    }

    @extend %display-flex;
    margin-bottom: 22px;

    .left-box,
    .right-box {
      flex: 1;
    }

    &.action-row {
      flex-wrap: wrap;

      .left-box {
        width: auto;
        min-width: 60px;
        margin-bottom: 5px;
      }

      .right-box {
        margin-left: auto;
        flex: auto;
        white-space: nowrap;
      }
    }
  }

  .header-text {
    margin-bottom: 22px;

    p {
      margin-bottom: 0px;
    }
  }

  .card-title {
    // font-family: $font-bold;
    font-weight: bolder;
    font-size: 14px;
    color: #000;
    margin-bottom: 2px;
  }

  .card-text {
    font-size: 14px;
    color: #000;
    line-height: 16px;

    span {
      display: inline-block;
    }
  }

  .button-box {
    padding-top: 8px;
  }
}

/*------------cart---------------*/
// .po-placeholder{
//  padding: 600px 600px;
// }
.upload-profilephoto {
  .upload-button {
    margin-left: 300px;
  }

  .file-name {
    left: 130px !important;
    position: relative;
    display: block;
    text-align: center;
  }

  .error-file {
    margin-left: 130px;
  }

  .green-title {
    font-size: 16px;
  }
}

.cart-section {
  .grid-header-actions {
    align-items: flex-end;

    .left-box {
      max-width: 597px;
    }
  }

  .app-grid-box {
    .no-product {
      position: absolute;
      margin-top: -100px;
    }

    .app-grid {
      display: table;

      .code-col,
      .price-col {
        min-width: 100px;
      }

      .desc-col {
        min-width: 300px;
      }

      .action-col {
        min-width: 188px;
      }

      tfoot {
        th,
        td {
          padding: 8px 8px;
          color: #000;
          font-size: 14px;
        }

        th {
          // font-family: $font-bold;
          font-weight: bolder;
          padding-right: 15px;
        }

        .value-text {
          border: 1px solid #000;
          box-shadow: -1px 0 0 0 #000;
        }
      }
    }
  }

  h3.box-title {
    font-size: 16px;
    // font-family: $font-bold;
    font-weight: bolder;
    color: $app-primary-color;
    text-transform: uppercase;
  }

  .date-select-box {
    text-align: center;
    padding-top: 60px;
    padding-bottom: 88px;

    .select {
      display: inline-block;
      padding: 0 10px;

      select {
        border: none;
        border-bottom: 1px solid #000;
        font-size: 14px;
        font-family: $font-light;
        color: #000;
        line-height: 38px;
        border-radius: 0px;
      }
    }
  }

  .select-payment-box {
    text-align: center;

    & > h3.box-title {
      margin-bottom: 17px;
    }

    .payment-options {
      .payment-chk {
        display: inline-block;
        padding: 0 17px;

        label {
          display: inline-block;
          position: relative;
          cursor: pointer;
          margin-bottom: 0px;

          &:hover .chk-style,
          input:checked ~ .chk-style {
            border-color: lighten($app-primary-color, 20%);
            background-color: lighten($app-primary-color, 20%);

            h3,
            i {
              color: #ffff;
            }
          }

          .chk-style,
          h3,
          i {
            @extend %common-transition;
          }

          .chk-style {
            min-width: 260px;
            border: 1px solid $app-primary-color;
            border-radius: 10px;
            padding: 16px;
            color: $app-primary-color;
            position: relative;
            padding: 9px 0 2px;
          }

          input {
            position: absolute;
            height: 100%;
            width: 100%;
            left: 0;
            top: 0;
            opacity: 0;
            cursor: pointer;
          }

          h3.box-title {
            margin-bottom: 12px;
          }

          i {
            font-size: 85px;
            line-height: 65px;
          }
        }
      }
    }
  }

  .footer-button-box {
    padding-top: 82px;
    padding-bottom: 85px;
  }
}

/*-------------loader----------------------*/
.sweet-loading {
  .css-198xogd {
    border-color: $app-primary-color $app-primary-color transparent;
  }
}

.bootstrap-demo .dropdown-trigger {
  border-radius: 0.25rem;
}

.bootstrap-demo .dropdown-trigger > span:after {
  font-size: 12px;
  color: #555;
}

.bootstrap-demo .toggle {
  font: normal normal normal 12px/1 FontAwesome;
  color: #555;
}

.bootstrap-demo .toggle.collapsed::after {
  content: "\f067";
}

.bootstrap-demo .toggle.expanded::after {
  content: "\f068";
}

.bootstrap-demo .root {
  padding: 0px;
  margin: 0px;
}

/*--------------customerAccReg-section---------------------*/
.stepForm-container {
  max-width: 1230px;
  padding: 0 15px;
  margin: 0 auto;
}

.customerAccReg-section,
.standupForm-section {
  overflow: hidden;

  .form-step {
    h4.green-title,
    p {
      font-size: 18px;
      color: $app-primary-color;
    }

    h2,
    h4,
    strong,
    h6 {
      // font-family: $font-bold;
      font-weight: bolder;
    }

    h2 {
      font-size: 34px;
      border-bottom: 1px solid #eee;
      padding-bottom: 20px;
    }

    .was-validated .form-control:invalid,
    .form-control.is-invalid {
      background-position: right 16px center;
    }

    .text-content {
      margin-bottom: 20px;
    }

    .step-common-section {
      // padding: 20px 0;
      // border-bottom: 1px solid #f6f6f6;
      padding: 20px 15px 5px;
      box-shadow: 0px 1px 4px 1px rgba(0, 0, 0, 0.11);
      margin: 17px 0;
    }

    .same-inputs-group {
      background: #eee;
      padding: 8px 8px 0;
      margin-bottom: 10px;

      .form-group {
        margin-bottom: 10px;
      }
    }

    .cell-bot-align {
      @extend %display-flex;
      align-items: flex-end;

      .form-group {
        flex: 1;
      }
    }

    .same-inputs-group {
      .with-add-remove-btns {
        padding-right: 0;
        padding-top: 40px;

        .add-remove-btn {
          position: absolute;
          top: 0;
          right: -8px;
        }
      }
    }
  }

  .step-button-box {
    padding-bottom: 20px;
    @extend %display-flex;

    .right-box {
      margin-left: auto;
    }

    button {
      &.step-btn {
        font-size: 20px;
        padding: 0 30px;
        height: 54px;
      }
    }
  }

  .step1 {
    padding: 60px 0px;

    h4 {
      margin-bottom: 40px;
    }

    h3.green-title,
    ol.documents-list {
      color: #1d5532;
    }

    ol.documents-list {
      padding-left: 26px;

      li {
        margin-bottom: 3px;
      }
    }

    .step-button-box {
      button {
        margin: 0 10px;
        text-transform: uppercase;
      }
    }
  }

  .step2 {
    .sub-header {
      margin-bottom: 25px;
      @extend %display-flex;
      justify-content: space-between;

      .reg-info {
        border: 1px solid #000;
        text-align: center;
        padding: 19px 50px;

        h5,
        p {
          margin-bottom: 0px;
          color: #000;
        }

        h5 {
          // font-family: $font-bold;
          font-weight: bolder;
          font-size: 18px;
        }

        p {
          font-size: 16px;
        }
      }
    }
  }

  .step3 {
    .Account-Secured-inputs {
      .cust-radio-group {
        margin-bottom: 5px;
      }
    }

    .title-trade {
      margin-top: 20px;
    }
  }

  .step4 {
    h3 {
      margin-bottom: 15px;
      font-size: 22px;
    }

    .documents-list {
      list-style-type: decimal;
      padding-left: 28px;

      li {
        font-size: 16px;
        margin-bottom: 8px;
        color: #353535;
        padding-left: 8px;
      }
    }

    .uploaded-documents-list {
      margin-bottom: 10px;

      li {
        position: relative;
        font-size: 18px;
        color: #353535;
        padding: 9px 47px 9px 14px;

        &:hover {
          background-color: #f4f4f4;
        }

        button {
          position: absolute;
          right: 0px;
          border: none;
          top: 0;
          height: 100%;
        }
      }
    }

    .upload-button {
      display: block;
      margin: 0 auto;
    }
  }

  .step5 {
    h4 {
      color: #000;
      font-size: 16px;
      margin-bottom: 15px;
    }
  }

  .step6 {
    .step-header {
      position: relative;
      padding: 0 180px;
      margin-bottom: 45px;

      .left-box {
        position: absolute;
        left: 0px;
        bottom: 20px;
      }

      .center-box {
        max-width: 690px;
        text-align: center;
        margin: 0 auto;

        img {
          width: 100px;
          margin-bottom: 10px;
        }

        h3 {
          font-size: 22px;
        }
      }
    }

    .list-title {
      font-size: 20px;
      margin-bottom: 15px;
    }

    .turms-list {
      padding-left: 25px;
      margin-bottom: 30px;

      li {
        display: block;
        position: relative;
        padding-left: 40px;
        line-height: 22px;
        margin-bottom: 12px;

        &:before {
          position: absolute;
          left: 0;
          font-size: 14px;
        }

        ul {
          padding-top: 10px;
          padding-left: 0px;

          li {
            padding-left: 50px;
          }
        }
      }

      @for $i from 1 through 30 {
        &.turms#{$i} {
          counter-reset: turmsItem;

          & > li {
            &:before {
              content: "#{$i}."counters(turmsItem, ".");
              counter-increment: turmsItem;
            }
          }
        }
      }
    }

    .accept-checkbox {
      text-align: center;
      margin-bottom: 28px;
    }
  }

  .step7 {
    h2 {
      font-size: 53px;
      border-bottom: 0;
      color: $app-primary-color;
    }

    p.step1-text {
      max-width: 500px;
      margin: 0 auto;
      margin-bottom: 40px;
    }
  }
}

/*----------userAccount-section-------------------------------*/

.useraccount-form {
  .inp {
    outline: 0;
    border-width: 0 0 1px;
    border-color: 1px solid #000000;
    padding-left: 0px;
  }
}

.useraccount-button {
  .save-send {
    height: 40px;
    text-align: center;
    background-color: $app-primary-color;
    color: #fff;
    line-height: 40px;
    margin-top: 75.5px;
    width: 390px;
    height: 40px;
    margin-left: 415px;
    padding: 0 10px;
    // font-family: $font-bold;
    font-weight: bolder;
    font-size: 16px;
    text-transform: uppercase;
    border-radius: 6px;
    border: none;

    &:hover,
    &:focus {
      background-color: #000;
      box-shadow: none;
      color: #fff;
    }

    &.full-width {
      width: 100%;
      min-width: auto;
    }
  }
}

/*----------userAccount-section-------------------------------*/
.account-header {
  margin-top: 3.5%;
}

.useraddAccount-section {
  h5.table-note {
    font-size: 14px;
    line-height: 16px;
    max-width: 597px;
    margin-bottom: 18px;
  }

  .user-table-data {
    .account-action {
      text-align: right;
      padding-right: 36px;
      min-width: 150px;
    }

    th.account-action {
      button {
        margin-left: 11px;
      }
    }
  }
}

/*-----------------userAddAccount-page---------------------*/
.account-header {
  margin-top: 3.5%;
}

.useraccount-section {
  .step-common-section {
    box-shadow: none;

    .form-group {
      input {
        border: none;
        border-bottom: 1px solid #000;
        padding-left: 0px;
        font-size: 14px;
      }

      ::-webkit-input-placeholder {
        /* Edge */
        color: #000;
      }

      :-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: #000;
      }

      ::placeholder {
        color: #000;
      }
    }
  }

  .content-page {
    padding-right: 318px;
  }

  .btn-box {
    padding-top: 60px;

    button {
      width: 100%;
    }
  }
}

/*--------------Switch-Account-section---------------------*/
.switch-account-header {
  margin-top: 3.5%;
  // .section-header{
  // .btn{
  // //   margin-left:59%;
  // //   min-width: 100px;
  // position: relative;
  // bottom: 13px;
  // //  margin-bottom: 5px;

  // }
  // }
  h2.with-abs-button {
    position: relative;

    button {
      position: absolute;
      right: 50px;
      top: 12px;
    }
  }
}

.switch-account-table-box {
  font-size: 14px;

  .switch-account-table {
    thead {
      color: #fff;
      background-color: $app-primary-color;
    }

    .code-col {
      padding-left: 80px;
    }

    .accountName-col {
      padding-right: 140px;
    }

    .switch-col {
      // padding-right: 0px;
    }
  }
}

/*--------------Open-Orders-section---------------------*/
.openorder-header {
  margin-top: 3.5%;
}

.openorders-table-box {
  // border-color: black !important;

  table {
    border-color: black !important;
  }

  .table-bordered th,
  .table-bordered td {
    border: 1px solid black;
  }

  // .table th, .table td {
  //     padding: 0.75rem;
  //     vertical-align: top;
  //     // border-top: 1px solid black;
  //     width: 500px;
  // }
  .openorders-table-data {
    min-width: 650px;

    thead {
      color: #fff;
      background-color: $app-primary-color;
      font-size: 14px;
    }

    tbody {
      font-size: 14px;
      color: #000;
      border-radius: 2px 2px 2px 2px;
    }

    .order-col {
      padding-left: 60px;
    }
  }
}

/*----------back-order-------------------------*/
.backorder-header {
  margin-top: 3.5%;

  .out-of-stock-header {
    margin-top: 4%;
  }
}

// Invoices
.invoice-table-box {
  // border-color: black !important;
  table {
    border-color: black !important;
  }

  .table-bordered th,
  .table-bordered td {
    border: 1px solid black;
  }
}

/*--------------Stock-Sheets-section---------------------*/

.stocksheet-section {
  .act-col {
    padding-right: 10px;
  }

  .stock-header {
    margin-top: 3.5%;
  }

  h5.table-note {
    font-size: 14px;
    line-height: 16px;
    max-width: 597px;
    margin-bottom: 18px;
  }

  .stocksheet-table-box {
    .stocksheet-table-data {
      min-width: 640px;

      .table-bordered th,
      .table-bordered td {
        border: 1px solid black;
      }

      thead {
        color: #fff;
        background-color: $app-primary-color;
        font-size: 14px;
      }

      .action-col {
        padding-right: 70px;
      }

      .act-button {
        margin-left: 8px;
        top: 432px;
        width: 30px;
        height: 30px;
        background: #d3d3d3 0% 0% no-repeat padding-box;
        border-radius: 2px;
        opacity: 1;
        display: inline-block;
      }

      tbody {
        font-size: 14px;
        color: #000;
        border-radius: 6px 2px 2px 2px;

        .stock-button {
          margin-right: -15%;
        }

        .stock-action {
          margin-right: 0;
        }

        th.stock-action {
          padding-right: 0px;

          button {
            top: 497px;
            left: 1469px;
            width: 15px;
            height: 15px;
          }
        }
      }
    }
  }
}

.act-col {
  padding-left: 50px;
}

/*-----------myaccount-section-------------------*/
.myaccount-header {
  margin-top: 3%;

  .myaccount-text {
    font-size: 20px;
  }
}

.myaccount-table-box {
  // border-color: black;
  .myaccount-table {
    font-size: 14px;

    thead {
      color: #fff;
      background-color: $app-primary-color;
    }

    .current-col {
      padding-left: 60px;
    }

    .days-col {
      margin-right: 20px;
    }
  }
}

.myact-table {
  border-color: black;
}

/*-------------stocksheetscreate----------------------*/

.stocksheets-create-header {
  .stocksheet-table {
    margin-top: 7.3%;
  }

  margin-top: 3.5%;
}

.stocksheets-create-placeholder {
  margin-top: 20%;

  .align-save-placeholder {
    padding-left: 45px;

    .file-icon {
      color: #1d5532;
      position: absolute;
      left: 12px;
      margin-top: 12px;
    }

    top: 255px;
    left: 1397px;
    height: 14px;
    opacity: 1;

    input {
      border: none;
      border-bottom: 1px solid #000;
      padding-left: 22px;
      font-size: 14px;
      display: inline;
      width: 65%;
    }

    ::-webkit-input-placeholder {
      /* Edge */
      color: #000;
    }

    :-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: #000;
    }

    ::placeholder {
      color: #000;
    }
  }

  .align-save-placeholder {
    display: inline;

    .save {
      top: 235px;
      color: white;
      left: 1675px;
      width: 99px;
      height: 40px;
      background: #1d5532 0% 0% no-repeat padding-box;
      border-radius: 6px;
      opacity: 1;
      display: inline;
      float: inline-end;
    }
  }
}

.stockcreate-table-box {
  margin-top: 55px;

  .stockcreate-table-data {
    max-width: 400px;
    width: 94%;

    thead {
      color: #fff;
      background-color: $app-primary-color;
      font-size: 14px;
    }

    tbody {
      font-size: 14px;
      color: #000;
      border-radius: 2px 2px 2px 2px;
    }
  }
}

/*----------standupform-section-------------------------------*/
.standupform-header {
  margin-top: -5%;
}

/*--------------order-section------------------*/
.order-printer {
  right: 0;
}

.order-value-text {
  font-size: 14px;
}

.order-section {
  tfoot {
    th,
    td {
      padding: 8px 8px;
      color: #000;
      font-size: 14px;
    }

    th {
      // font-family: $font-bold;
      font-weight: bolder;
      padding-right: 15px;
    }

    .order-value-text {
      border: 1px solid #000;
      box-shadow: -1px 0 0 0 #000;
    }
  }

  .order-header {
    .order-text {
      font-size: 14px;
    }

    .order-middle {
      font-size: 30px;
      color: $app-primary-color;
      font-family: Helvetica Neue;
    }

    .order-right-text {
      font-size: 14px;
    }
  }
}

/*-----------rep client contact history-------------------*/
.rep-client-history-header {
  margin: 3.5%;

  .contact-message {
    z-index: 1;
    color: black;
  }

  .message-textarea {
    background: #1d553233 0% 0% no-repeat padding-box;
  }

  .rep-client-history-save {
    margin-top: 2.5%;
    top: 500px;
    left: 1468px;
    width: 99px;
    height: 40px;
    background: var(--unnamed-color-1d5532) 0% 0% no-repeat padding-box;
    background: #1d5532 0% 0% no-repeat padding-box;
    border-radius: 6px;
    opacity: 1;
    float: right;
    color: white;
  }
}

.rep-client-history-table-box {
  margin-top: 5.5%;
  font-size: 14px;

  .rep-client-history-table {
    thead {
      color: #fff;
      background-color: $app-primary-color;
    }

    .code-col {
      padding-left: 80px;
    }

    .accountName-col {
      padding-right: 120px;
    }

    .message-col {
      padding-right: 190px;
    }

    .view-col {
      padding-right: 20px;
    }
  }
}

.date-filter {
  text-align: center;
  display: flex;
  flex-direction: column;
}

/*----------report-section-------------------------------*/
.report-section {
  .report-header {
    margin-top: 3.5%;

    .from-date-input {
      margin-left: 22%;
      border: none;
      border-bottom: 1px solid #000;
      padding-left: 0px;
      font-size: 14px;
    }

    .to-date-input {
      margin-left: 60%;
      border: none;
      border-bottom: 1px solid #000;
      padding-left: 0px;
      font-size: 14px;
    }

    ::-webkit-input-placeholder {
      /* Edge */
      color: #000;
    }

    :-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: #000;
    }

    ::placeholder {
      color: #000;
    }
  }

  .customer-report {
    margin-left: 10%;
    margin-top: 15%;

    .rep-customer-report {
      display: inline;
      margin-left: 10%;
    }

    .revenue-report {
      display: inline;
      margin-left: 10%;
    }

    .orders-report {
      display: inline;
      margin-left: 10%;
    }
  }
}

/*-----------print products-------------------*/

.print-product-card {
  border: 1px solid #000;
  margin-top: 3%;
  position: relative;
  border-radius: 0px;
  margin-bottom: 20px;

  .app-chk .chk-style {
    background-color: rgba(255, 255, 255, 0.69);
  }

  .app-chk {
    position: absolute;
    top: 8px;
    left: 8px;
    z-index: 1;
  }

  .card-body {
    border-top: 1px solid #000;
  }

  .print-product-image {
    height: 213px;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center center;
    -webkit-background-size: 100% 100%;
    -moz-background-size: 100% 100%;
    -o-background-size: 100% 100%;
    background-size: 100% 100%;
  }

  .card-row {
    &:last-child {
      margin-bottom: 0px;
    }

    @extend %display-flex;
    margin-bottom: 22px;

    .left-box,
    .right-box {
      flex: 1;
    }

    &.action-row {
      flex-wrap: wrap;

      .left-box {
        width: auto;
        min-width: 60px;
        margin-bottom: 5px;
      }

      .right-box {
        margin-left: auto;
        flex: auto;
        white-space: nowrap;
      }
    }
  }
}

page[size="A4"] {
  background: white;
  width: 21cm;
  height: 29.7cm;
  display: block;
  margin: 0 auto;
  margin-bottom: 0.5cm;

  .card-body {
    border-top: 1px solid #000;
  }
}

page {
  background: white;
  display: block;
  margin: 0 auto;
  margin-bottom: 0.5cm;
}

page[size="A4"][layout="landscape"] {
  width: 29.7cm;
  height: 21cm;
}

/*----------standformagreement-section-------------------------------*/
.r-text {
  color: #000;
}

.agreement-header {
  margin-top: -5%;
}

.green-title-agreement {
  color: $app-primary-color;
}

.black-title-agreement {
  color: #000;
}

.standform-agreement {
  color: #000;
  font-size: 14px;

  .r-text {
    color: #000;
    font-size: 14px;

    .submit-formagreement {
      button {
        float: right;
      }
    }
  }

  .agreement-input {
    box-shadow: none;
    border: none;
    border-bottom: 1px solid #000;
    padding-left: 0px;
    font-size: 14px;
  }
}

.sigPad {
  width: 100%;
  height: 300px;
}

.AccountNavActive {
  font-size: 25px;
}

/*----------------product-details-view----------------*/
.product-details-view {
  display: flex;
  flex-wrap: wrap;

  .product-image-wrapper,
  .product-details {
    width: 50%;
  }

  .product-image-wrapper {
    .product-image {
      height: 590px;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: contain;
      border: 1px solid #000;
    }
  }

  .product-details {
    padding-left: 40px;

    h3.productname {
      font-size: 30px;
      line-height: 36px;
      color: #000;
      margin-bottom: 36px;
    }

    .product-summary-details {
      margin: 0px;

      .col {
        padding: 0px;
      }

      .detail-wrapper {
        margin-bottom: 40px;

        h5,
        p {
          line-height: normal;
          font-size: 14px;
        }

        h5 {
          // font-family: $font-bold;
          font-weight: bolder;
          margin-bottom: 3px;
        }

        p {
          margin-bottom: 0px;
        }
      }
    }

    .product-quantity {
      margin-bottom: 60px;
    }
  }

  .product-description {
    margin-bottom: 50px;

    p {
      font-size: 14px;
      color: #000;
      line-height: 16px;
      margin-bottom: 0px;
    }
  }

  .product-more-detail {
    padding-top: 58px;
  }

  h4.products-title {
    font-size: 16px;
    font-family: $font-boldItalic;
    color: $app-primary-color;
    margin-bottom: 16px;
  }

  .no-font {
    font-size: 14px;
  }
}

/*------------*/
.useraccount-section.useraccountadd-section
  .access-control-section
  .form-group {
  margin-bottom: 22px;
}

.useraccount-section.useraccountadd-section
  .access-control-section
  .app-chk
  .chk-text {
  font-size: 20px;
}

.useraccount-section.useraccountadd-section
  .access-control-section
  .clearfix
  .app-chk
  .chk-text {
  font-size: 16px;
  color: #161616;
}

.useraccount-section.useraccountadd-section .btn-box {
  padding-top: 22px;
  padding-bottom: 20px;

  button {
    width: 150px;
    margin-right: 15px;
  }
}

.css-1pahdxg-control {
  border-color: #1d5532 !important;
  box-shadow: 0 0 0 1px #1d5532 !important;
}

.requiredClass {
  font-weight: 900;
  color: red;
}

.pop-over {
  text-transform: uppercase;
}

.popover {
  max-width: 600px;
  padding: 20px 20px;
  background-color: #1d5532;
  left: 8% !important;
  border-radius: 0rem !important;
}

.popover-body {
  color: white;
  text-transform: uppercase;
  font-style: italic;
  font-weight: bold;
  text-align: left;
}

.popover .arrow {
  border-top-color: #1d5532;
  left: 10% !important;
  width: 100px;
  color: #1d5532 0% 0% no-repeat padding-box;
  position: fixed;
}

.arrow::after {
  color: #1d5532;
}

.popover .tooltip {
  color: #1d5532;
}

.remove-style {
  padding: 0;
  margin: 0;
  border: none;
  background: none;
  color: $app-primary-color;
}

.remove-style:hover {
  padding: 0;
  margin: 0;
  border: none;
  background: none;
  color: black;
}

.change-pointer {
  cursor: pointer;
}

.change-pointer:hover {
  cursor: pointer;
  color: #054f7b;
}

.cursor-category {
  border: 1px solid black;
  border-radius: 50%;
}

.cursor-category:hover {
  background-color: #054f7b;
  color: #fff;
}

//  Add category button
.add-categorybtn {
  font-size: 11px;
}

.add-subscribersbtn {
  font-size: 11px;
}

// Modal in home.js

.modal-dialog {
  width: 100%;
  // margin: 0rem!important;
}

// .main-header.login-header .top-nav .navbar {
//   padding: 15px 0px !important;
// }
.nav-Image-width.row {
  padding: 10px 0;
}

.header-search {
  max-width: 35px;
  margin-right: 20px;
}

.main-header {
  .top-nav {
    .cust-container {
      padding: 10px 15px;
    }
  }
}

.app-grid-box {
  .app-grid {
    display: table;

    thead {
      th {
        background-color: #054f7c;
        color: #fff;
      }
    }
  }
}

.side-menu-nav li .nav-link:hover,
.side-menu-nav li .nav-link.active {
  color: #054f7c;
}

/*aboutus*/
.top-main-banner {
  height: 440px;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;

  .top-text {
    position: absolute;
    top: 50%;
    left: 10%;
    transform: translate(0%, -50%);

    h1 {
      width: 467px;
      color: #ffffff;
      font-size: 50px;
      font-weight: 700;
      text-transform: uppercase;

      span {
        display: block;
      }
    }
  }
}

.about-content {
  padding-top: 50px;

  .about-text {
    h4 {
      color: $app-primary-color;
      font-size: 30px;
      font-weight: 700;
      margin-bottom: 40px;
    }
  }

  .about-paragraph {
    font-size: 20px;
    padding: 0px 10px 0 0;

    p {
      margin-bottom: 30px;
    }
  }

  .about-img {
    img {
      width: 100%;
    }
  }
}

.contact-form {
  margin-top: 70px;
  padding-bottom: 50px;
  border-bottom: 2px solid $app-primary-color;

  p {
    margin-bottom: 40px;
  }

  .form-main {
    button {
      width: 288px;
      height: 60px;
      border: 1px solid #333a45;
      color: #3f3f41;
      background-color: transparent;
      font-weight: 700;
      text-transform: uppercase;
    }

    .form-control {
      border-color: #3f3f41;
    }
  }
}

.contact-map {
  margin-top: 100px;

  h3 {
    margin-bottom: 40px;
  }

  p,
  a {
    color: #969696;
  }

  strong {
    font-size: 20px;
    color: #231f20;
    font-weight: 700;
  }

  .row {
    margin-bottom: 50px;
  }

  .maps iframe {
    width: 100%;
  }

  .map-sub {
    margin-bottom: 30px;
  }
}
.contact-map-heading {
  font-size: 24px;
  color: $app-primary-color;
  font-weight: bold;
}

.cust-container.cust-slider {
  .left-box.note-text {
    width: 100%;
  }

  .note-text {
    .note-title {
      color: #054f7b;
      line-height: 28px;
      border-bottom: 3px solid #054f7b;
      position: relative;
      margin-bottom: 15px !important;
    }

    button.btn.common-button.btn-link.view.remove-style {
      position: absolute;
      bottom: 0;
      right: 35px;
      top: inherit !important;
    }
  }

  + * .table-responsive {
    overflow-x: inherit;
  }
}

.catalogue-card {
  .card-row {
    .box-buttons {
      position: relative;
      max-height: 30px;

      a.up-arrow,
      a.down-arrow {
        background-image: url(/static/media/Path_53.7d740fe5.svg);
        display: inline-block;
        width: 10px;
        height: 10px;
        position: absolute;
        right: 35px;
        bottom: 0;
        margin: auto;
        background-repeat: no-repeat;
        top: -8px;
        left: auto;
      }

      a.down-arrow {
        background-image: url(../images/Path_54.svg);
        top: 16px !important;
      }

      a.up-arrow {
      }
    }

    margin-bottom: 0;

    input[type="text"] {
      height: 30px;
      width: 80px;
      padding-right: 48px;
      background-color: #054f7b;
      border: 0;
      border-radius: 3px;
      color: #fff;
      padding-left: 5px;
    }

    .action-button {
      position: absolute;
      right: 0;

      span {
        font-size: 26px;
        font-weight: 500;
        line-height: 26px;
      }
    }
  }

  .header-text {
    margin-bottom: 40px;

    .card-title {
      color: #054f7b;
      font-size: 12px;
    }
  }
}

.sc-bdVaJa {
  span.kVtMtA {
    position: absolute;
    top: calc(50% - 17.5px);
    height: 60px;
    width: 20px;
    background: #054f7b;
    border-radius: 0;
    box-shadow: none;
    z-index: 1;
    cursor: pointer;
    font-size: 10px;
    opacity: 1;
    -webkit-transition: opacity 0.25s;
    transition: opacity 0.25s;
    left: -7px;
    right: initial;

    &::before {
      border-color: #fff;
    }
  }

  span.bhRnqO {
    position: absolute;
    top: calc(50% - 17.5px);
    height: 60px;
    width: 20px;
    background: #054f7b;
    border-radius: 0;
    box-shadow: none;
    z-index: 1;
    cursor: pointer;
    font-size: 10px;
    opacity: 1;
    -webkit-transition: opacity 0.25s;
    transition: opacity 0.25s;
    right: -7px;
    left: initial;

    &::before {
      border-color: #fff;
    }
  }
}

.form-group {
  input[type="file"] {
    + .thumbnail {
      width: 80px;
      margin-top: 10px;
      display: inline-block;
    }
  }
}

.sub-category-main {
  .sub-category-box {
    margin-bottom: 40px;

    a {
      display: block;
      position: relative;

      &::before {
        content: "";
        display: block;
        padding-top: 80%;
      }

      img {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        max-width: 100%;
        max-height: 100%;
        height: 100%;
        margin: auto;
        transition: all 0.3s ease;
        -webkit-transform: translateZ(0) scale(1, 1);
      }
    }

    .sub-category-name {
      padding: 20px 10px;
      text-align: center;
      background-color: #58585a;
      color: #fff;

      h4 {
        text-transform: uppercase;
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 0;
        letter-spacing: 0.8px;
      }
    }
  }
}

/*-----------responsive-------------------*/
@import "responsive.scss";

// product print
main.pb-0 {
  .printProduct-section {
    .main-product-print-footer {
      position: unset !important;
    }
  }

  .login-footer .main-footer .center-box {
    position: unset !important;
    margin: 0 !important;
  }
}

.app-grid-box .app-grid.with-border th.status-col {
  width: 200px !important;

  @media all and (max-width: 767px) {
    width: unset !important;
  }
}

.red-color {
  background-color: red;
}

// caatalogue sidebar style
.with-sidebar {
  position: relative;
}

.with-sidebar .sidebar-content.ctlg-sidebar-content {
  max-width: 1354px;
}

.product-ctlg-close {
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
  background-color: #054f7c;
  padding: 8px 24px;
  color: #fff;
  text-transform: uppercase;

  svg {
    margin-right: 15px;
  }
}

.ctlg-sidebar-content {
  .product-details-view .product-image-wrapper {
    width: 40%;
  }

  .product-details-view .product-details {
    width: 60%;
  }

  .product-details-view .product-details .product-summary-details .col {
    min-width: unset;
    max-width: unset;
  }
}

.ctlg-product-info {
  font-size: 14px;
}

.ctlg-product-title {
  color: #054f7c !important;
}

.ctlg-product-table {
  thead {
    background-color: #054f7c;
    color: #fff;
  }

  tbody {
    tr {
      &:nth-child(odd) {
        background-color: #efeff0;
      }

      &:nth-child(even) {
        background-color: #dfdfe0;
      }
    }

    td {
      line-height: 1;
      vertical-align: middle;
    }

    th,
    td {
      font-weight: 400;
    }
  }
}

.ctlg-stock-col,
.ctlg-qty-col {
  min-width: 105px;
}

.ctlg-qty-input-group {
  input {
    color: #fff;
    border-radius: 5px;
    width: 55px;
    padding-right: 5px;
  }

  .action-button {
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: -5px;
  }
}

.catalogue-card .card-row .ctlg-qty-input-group .action-button {
  position: unset;
}

.ctlg-list-title {
  color: #054f7c;
  font-family: "Futura Md BT";
  font-size: 30px;
  font-weight: 700;
}

.back-to-btn {
  display: flex;
  align-items: center;
  background-color: #054f7c;
  border-radius: 0;
  padding: 8px 24px;

  img {
    margin-right: 30px;
  }

  span {
    color: #ffffff;
    font-size: 16px;
    font-weight: 500;
    text-transform: uppercase;
  }

  &:hover {
    background-color: #054f7c;
  }
}

.ctlg-list-back-btn {
  margin-top: -35px;
  margin-bottom: 35px;
}

.sidebar-wrapper.ctlg-list-sidebar-wrapper {
  padding-top: 100px;
}

// login page style

.login-section {
  .login-box {
    padding: 20px 0;

    .login-form {
      max-width: 475px;

      .form-group .form-control {
        height: 45px;
      }
    }

    .section-header {
      padding: 0 35px;

      span {
        max-width: 455px;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }

  .btn {
    border-radius: 0;
  }

  .dropdown-menu {
    padding: 0;
    border-radius: 0;
  }

  .dropdown-item {
    padding-top: 8px;
    padding-bottom: 8px;

    &:hover,
    &:focus {
      background: #054f7c;
      color: #fff;
    }
  }

  .dropdown-divider {
    margin: 0;
  }

  .common-button {
    max-width: unset;
  }
}

// navbar style
.product-ctgry-dropdown {
  margin: 0 !important;
  padding: 0 !important;

  .nav-link {
    padding: 0 !important;

    &::after {
      display: none;
    }
  }

  .dropdown-menu {
    padding: 0;
    border-radius: 0;
  }

  .dropdown-item {
    padding-top: 10px;
    padding-bottom: 10px;
    line-height: 1.2;

    &:hover,
    &:focus {
      background: #054f7c;
      color: #fff;
    }
  }
}

// home page promotional carousel
.home-promotional-carousel {
  .catalogue-card {
    .product-image {
      height: 250px;
    }
    .right-box {
      margin-right: 15px;
    }
  }
  @media (max-width: 767px) {
    .dQMmGc {
      grid-template-columns: repeat(2, 1fr);
    }
    .jJoQOU {
      grid-template-columns: repeat(1, 1fr);
      padding-left: 0;
    }
  }
  .sc-bdVaJa {
    span.kVtMtA {
      left: -15px;
    }

    span.bhRnqO {
      right: -15px;
    }
  }
}

// catalogue page categories list style
.catalogue-section {
  .side-menu .card {
    .card-header {
      padding-right: 40px;
    }

    .card-body {
      padding-left: 15px;
    }
  }
}

.catalogue-card .header-text {
  margin-bottom: 12px;
}

.cart-section .element-group input {
  color: #fff;
}

// cart page radio input style

.cart-py-radio-container {
  .form-check {
    padding-left: 25px;
  }

  .form-check-input {
    width: 18px;
    height: 18px;
    margin-left: -25px;
    margin-top: 3px;
  }
}

@media (max-width: 1035px) {
  // .sidebar-wrapper.ctlg-list-sidebar-wrapper {
  //   padding-top: 0px;
  // }

  .ctlg-list-back-btn {
    margin-top: 0;
  }

  .product-ctlg-close + .sidebar-content {
    padding-top: 55px;
  }
}

@media (max-width: 768px) {
  .ctlg-sidebar-content {
    .product-details-view .product-image-wrapper {
      width: 100%;
    }

    .product-details-view .product-details {
      width: 100%;
    }
  }

  .ctlg-list-grid-header-actions {
    flex-direction: column;

    .ctlg-list-title {
      margin-bottom: 24px;
    }
  }

  .content-page.catalogue-section {
    padding-top: 0px;
  }

  .ctlg-list-grid-header-actions .right-box {
    margin-left: -18px;
  }

  .product-ctlg-close + .sidebar-content {
    padding-top: 40px;
  }
}

@media (max-width: 576px) {
  .grid-header-actions {
    flex-direction: column;
    align-items: flex-start;

    .right-box {
      margin-left: 0;
    }
  }
  .news-grid {
    .Title-col {
      min-width: unset;
    }
    .Content-col {
      min-width: unset;
    }
    .ctlg-stock-col,
    .ctlg-qty-col {
      min-width: unset;
    }
  }
}

// b2c navbar css
header.main-header.main-header-btc {
  box-shadow: unset;

  @media (min-width: 991px) {
    .app-links-nav {
      background: transparent;
      margin-left: auto;
      margin-right: 0;

      .cust-container {
        margin-right: 0;
      }

      .nav-link {
        font-weight: bold;
        color: #3f3f41;

        &:hover {
          color: #3f3f41;
        }
      }
    }

    .user-login-wrapper {
      order: 7;
    }

    .user-login {
      position: static;
    }
  }

  @media (max-width: 991px) {
    .app-links-nav {
      .cust-container {
        padding: 0;
      }
      .nav {
        padding-top: 20px;
      }
    }
  }
}
.main-header .app-links-nav .menu-close-button {
  z-index: 3;
}

// b2c footer css
.main-footer.main-footer-btc {
  display: flex;
  background: transparent;
  padding: 20px 40px;
  border-top: 2px solid #054f7c;

  @media (max-width: 991px) {
    flex-direction: column;
    align-items: center;
  }

  .footer-logo {
    max-width: 280px;

    @media (max-width: 991px) {
      max-width: 300px;
    }
  }

  .cust-container {
    flex: 1;
    display: flex;

    @media (min-width: 991px) {
      padding-left: 8px;
      flex-direction: row;
      justify-content: space-between;
    }
  }

  .left-box {
    color: #58585a;

    @media (max-width: 991px) {
      margin-top: 20px;
      margin-bottom: 15px;
    }

    span {
      border-left: 1px solid #58585a;
      margin-left: 5px;
      padding-left: 5px;
    }
  }

  nav li {
    margin: 0;
    padding: 0;

    p {
      padding: 0 7px;
      text-decoration: none;
      color: #58585a;
      font-size: 14px;
      border-right: 1px solid #58585a;
      border-bottom: 1px solid #58585a;
      line-height: 1;

      &::before {
        display: none;
      }
    }

    &:nth-child(1) {
      p {
        padding-left: 0;
      }
    }

    &:nth-last-child(1) {
      p {
        border-right: none;
        padding-right: 0;
      }
    }
  }
}
// loading spinner css
.sweet-loading-wrapper {
  background: rgba(211, 211, 211, 0.9);
  border-radius: 5px;
  padding: 15px 15px 10px 15px;
}
// home, about and contact page banner css
.btc-custom-page-banner {
  @media (max-width: 768px) {
    margin-top: 25px;
  }
}

.home-top-main-banner .top-text h1 {
  width: auto;
}

// b2c custom headign style
.btc-custom-heading1 {
  color: #054f7c;
  font-weight: bold;
  font-size: 40px;

  @media (max-width: 1366px) {
    font-size: 34px;
  }
  @media (max-width: 1199px) {
    font-size: 32px;
  }

  @media (max-width: 768px) {
    font-size: 28px;
  }
}

.btc-custom-heading2 {
  color: #054f7c;
  font-weight: bold;
  font-size: 30px;

  @media (max-width: 1199px) {
    font-size: 28px;
  }
}

.btc-custom-text1 {
  color: #333a45;
  font-size: 14px;
}

.btc-custom-text2 {
  color: #333a45;
  font-size: 14px;
}

.btc-custom-btn1 {
  color: #3f3f41;
  border: 1px solid #3f3f41;
  border-radius: 0;
  padding: 12px 40px;
  font-size: 22px;
  font-weight: bold;
  text-transform: uppercase;
  min-width: 288px;
  background: transparent;
  box-shadow: none;
  @media (max-width: 1199px) {
    font-size: 18px;
  }
  @media (max-width: 768px) {
    font-size: 16px;
  }
}

// home products list style
.btc-product-custom {
  text-align: center;
  cursor: pointer;

  .product-img-box {
    border: 1px solid #3f3f41;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 225px;
    img {
      max-width: 100%;
      max-height: 100%;
    }
  }

  .product-title {
    background-color: #58585a;
    font-size: 22px;
    font-weight: bold;
    color: #fff;
    padding: 10px;
    text-transform: uppercase;
    @media (max-width: 1199px) {
      font-size: 18px;
    }
    @media (max-width: 768px) {
      font-size: 16px;
    }
    > * {
      margin: 0;
    }
  }
}
.contact-form .form-group {
  text-align: left;
}

.login-footer {
  .main-footer {
    height: auto !important;
  }
}
.cart-py-radio-container {
  max-width: max-content;
  margin: auto;
}

// layout padding style
.btc-layout-padding-top {
  padding-top: 80px;
  @media (max-width: 768px) {
    padding-top: 90px;
  }
}
.btc-layout-padding-bottom {
  padding-bottom: 120px;
  @media (max-width: 768px) {
    padding-bottom: 90px;
  }
}
.btc-layout-padding-top2 {
  padding-top: 70px;
  @media (max-width: 768px) {
    padding-top: 40px;
  }
}
.btc-layout-padding-bottom2 {
  padding-bottom: 70px;
  @media (max-width: 768px) {
    padding-bottom: 40px;
  }
}

// cart page css
.container.btc-cart-container {
  @media (min-width: 1600px) {
    max-width: 1544px;
  }
}

// home page banner carousel
.home-banner-container {
  .carousel-indicators li {
    width: 15px;
    height: 15px;
    border-radius: 50%;
  }
}

.c-pointer {
  cursor: pointer;
}

// sidemenu checkbox
.side-menu .app-chk {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  min-height: 30px;
}

.ctlg-product-table tbody td,
.ctlg-product-table tbody th {
  font-size: 12px;
  padding-top: 2px;
  padding-bottom: 2px;
  vertical-align: middle;
}
.custom-scrollbar {
  overflow: auto;
  scrollbar-width: thin;
  scrollbar-color: #bdbdbd transparent;
  margin-bottom: 5px;
  /* Works on Chrome, Edge, and Safari */
  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #bdbdbd;
    border-radius: 20px;
  }
}
div#toggler {
  position: relative;
  background-color: #acc1ce;
  .cart-title {
    border-radius: 0;
    justify-content: center;
    font-weight: normal;
    font-size: 18px;
    padding: 6px;
    text-align: center;
    display: flex;
  }
  span {
    margin-top: 0.4rem;
    font-size: 22px;
    color: rgb(5, 79, 123);
  }
  .toggler-btn-div {
    margin-left: auto;
  }
  .toggler-btn {
    background-color: #054f7b;
  }
}
